import React, { ReactNode } from 'react';
import Head from 'next/head';
import { PWATooltip } from '../components/PWATooltip/PWATooltip';
import { useUser } from '../hooks/useUser';
import Login from '../components/Login/Login';

export const Index = (_props: TIndexProps) => {
  useUser();

  return (
    <React.Fragment>
      <Head>
        <title>TIM - Positivador</title>
      </Head>
      <Login />
      <PWATooltip />
    </React.Fragment>
  );
};

export default Index;

export type TIndexProps = {
  className?: string;
  children?: ReactNode;
};
