import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Button, message } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import smBackgroundImg from '../../assets/BG_BG.png';
import { Logo } from '../Logo/Logo';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useLogin } from '../../hooks/useLogin';
import { formatCnpj } from '@digi-tim-19/utils';
import { useClient } from '../../../client/client';

export const Login = () => {
  const router = useRouter();
  const { status, login } = useLogin();
  const [pageState, setPageState] = useState('input_cnpj');
  const [canSeePassword, setCanSeePassword] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [maskedEmail, setMaskedEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [payload, setPayload] = useState({ cnpj: '', password: '' });

  const checkClient = useClient('MerchanUserCheck', {
    fragment: `
      active
      firstAccess
      tempPassword
      blockedByAtempts
      maskedEmail
    `
  });
  const requestClient = useClient('MerchanRequestTempPassword');
  const newPassClient = useClient('MerchanChangePassword');

  const isPending = status === 'pending';

  const validSize = newPassword.length >= 8;
  const validLetters = /[A-Z]/.test(newPassword) && /[a-z]/.test(newPassword);
  const validNumbers = /\d/.test(newPassword);
  const validChars = /[^a-zA-Z0-9]/.test(newPassword);
  const validPassword =
    newPassword === confirmPassword &&
    validSize &&
    validLetters &&
    validNumbers &&
    validChars;

  switch (pageState) {
    case 'input_cnpj': {
      return (
        <PageWrapper>
          <LogoWrapper>
            <Logo height={'40px'} />
          </LogoWrapper>
          <FormWrapper>
            <FormTitle>Login</FormTitle>
            <Input
              placeholder="CNPJ"
              prefix={<UserOutlined />}
              maxLength={14}
              value={formatCnpj(payload.cnpj)}
              onChange={(evt) => {
                const parsedValue = evt.target.value.replace(/[^0-9]+/g, '');
                setPayload({ ...payload, cnpj: parsedValue });
              }}
            />
            <Button
              type="primary"
              shape="round"
              size="large"
              loading={checkClient.loading}
              disabled={payload.cnpj.length < 14 || checkClient.loading}
              onClick={async () => {
                checkClient
                  .fetch({ variables: { CNPJ: payload.cnpj } })
                  .then(({ result, errors }) => {
                    if (result?.blockedByAtempts) {
                      message.error(
                        'Usuário bloqueado por mais de 10 tentativas incorretas'
                      );
                      return;
                    }

                    if (result?.firstAccess) {
                      message.info(
                        'No primeiro acesso a senha precisa ser resetada'
                      );
                      setPageState('verify_email');
                      return;
                    }

                    if (result?.tempPassword) {
                      message.info('Senhas temporárias precisam ser alteradas');
                      setPageState('temp_password');
                      return;
                    }

                    if (!result?.active) {
                      message.error('Usuário inativo no sistema');
                      return;
                    }

                    if (errors?.length) {
                      message.error(errors.join(', '));
                      return;
                    }

                    setMaskedEmail(result?.maskedEmail);
                    setPageState('input_password');
                  });
              }}
            >
              Próximo
            </Button>
            {payload.cnpj && (
              <FooterWrapper
                onClick={() => {
                  checkClient
                    .fetch({ variables: { CNPJ: payload.cnpj } })
                    .then(({ result }) => {
                      if (result?.maskedEmail) {
                        setMaskedEmail(result?.maskedEmail);
                        setPageState('verify_email');
                      }
                    });
                }}
              >
                Esqueceu sua senha?
              </FooterWrapper>
            )}
          </FormWrapper>
        </PageWrapper>
      );
    }
    case 'input_password': {
      return (
        <PageWrapper>
          <LogoWrapper>
            <Logo height={'40px'} />
          </LogoWrapper>
          <FormWrapper>
            <FormTitle>Login</FormTitle>
            <Input
              type={canSeePassword ? 'text' : 'password'}
              placeholder="Senha"
              prefix={<LockOutlined />}
              value={payload.password}
              suffix={
                canSeePassword ? (
                  <EyeOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(false)}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(true)}
                  />
                )
              }
              onChange={(evt) => {
                setPayload({ ...payload, password: evt.target.value });
              }}
            />
            <Button
              type="primary"
              shape="round"
              size="large"
              loading={!isPending}
              disabled={!Boolean(payload.password) || !isPending}
              onClick={async () => {
                const success = await login(payload);
                if (success) {
                  // router.push('/presentation');
                  router.push('/positivador');
                } else {
                  message.error(
                    'Não foi possível efetuar o login. Verifique o cnpj e senha informados'
                  );
                }
              }}
            >
              Acessar
            </Button>
          </FormWrapper>
          <FooterWrapper onClick={() => setPageState('verify_email')}>
            Esqueceu sua senha?
          </FooterWrapper>
        </PageWrapper>
      );
    }
    case 'verify_email': {
      return (
        <PageWrapper>
          <LogoWrapper>
            <Logo height={'40px'} />
          </LogoWrapper>
          <FormWrapper>
            <FormTitle>Login</FormTitle>
            <FooterWrapper>{maskedEmail}</FooterWrapper>
            <Input
              placeholder="Email"
              prefix={<UserOutlined />}
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <Button
              type="primary"
              shape="round"
              size="large"
              loading={requestClient.loading}
              disabled={!Boolean(email) || requestClient.loading}
              onClick={() => {
                requestClient
                  .fetch({ variables: { email, CNPJ: payload.cnpj } })
                  .then(({ result, errors }) => {
                    if (result) {
                      message.success(`Email enviado para ${email}`);
                      setPageState('temp_password');
                    } else {
                      message.error(`Verifique o email e tente novamente`);
                    }

                    if (errors?.length) {
                      message.error(errors.join(', '));
                      return;
                    }
                  });
              }}
            >
              Verificar email
            </Button>
          </FormWrapper>
          <FooterWrapper onClick={() => setPageState('input_cnpj')}>
            Voltar
          </FooterWrapper>
        </PageWrapper>
      );
    }
    case 'temp_password': {
      return (
        <PageWrapper>
          <LogoWrapper>
            <Logo height={'40px'} />
          </LogoWrapper>
          <FormWrapper>
            <FormTitle>Login</FormTitle>
            <Input
              type={canSeePassword ? 'text' : 'password'}
              placeholder="Senha temporária"
              prefix={<LockOutlined />}
              value={payload.password}
              suffix={
                canSeePassword ? (
                  <EyeOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(false)}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(true)}
                  />
                )
              }
              onChange={(evt) => {
                setPayload({ ...payload, password: evt.target.value });
              }}
            />
            <Button
              type="primary"
              shape="round"
              size="large"
              disabled={!Boolean(payload.password)}
              onClick={() => setPageState('new_password')}
            >
              Próximo
            </Button>
          </FormWrapper>
          <FooterWrapper onClick={() => setPageState('verify_email')}>
            Voltar
          </FooterWrapper>
        </PageWrapper>
      );
    }
    case 'new_password': {
      return (
        <PageWrapper>
          <LogoWrapper>
            <Logo height={'40px'} />
          </LogoWrapper>
          <FormWrapper>
            <FormTitle>Login</FormTitle>
            <p style={{ color: 'white' }}>
              É responsabilidade de usuário manter o sigilo de suas senhas para
              acesso ao sistema.
            </p>
            <Input
              type={canSeePassword ? 'text' : 'password'}
              placeholder="Nova Senha"
              value={newPassword}
              prefix={<LockOutlined />}
              suffix={
                canSeePassword ? (
                  <EyeOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(false)}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(true)}
                  />
                )
              }
              onChange={(evt) => {
                setNewPassword(evt.target.value);
              }}
            />
            <Input
              type={canSeePassword ? 'text' : 'password'}
              placeholder="Confirmar Senha"
              value={confirmPassword}
              prefix={<LockOutlined />}
              suffix={
                canSeePassword ? (
                  <EyeOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(false)}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    className="icon-password-custom"
                    onClick={() => setCanSeePassword(true)}
                  />
                )
              }
              onChange={(evt) => {
                setConfirmPassword(evt.target.value);
              }}
            />
            <div style={{ maxHeight: '120px' }}>
              {validSize && <Info>8 ou mais caracteres</Info>}
              {validLetters && (
                <Info>Inclui letras maiusculas e minúsculas</Info>
              )}
              {validNumbers && <Info>Inclui números</Info>}
              {validChars && <Info>Inclui outros caracteres</Info>}
            </div>
            <Button
              type="primary"
              shape="round"
              size="large"
              loading={newPassClient.loading}
              disabled={!validPassword || newPassClient.loading}
              onClick={() => {
                newPassClient
                  .fetch({
                    variables: {
                      CNPJ: payload.cnpj,
                      newPassword,
                      oldPassword: payload.password
                    }
                  })
                  .then(({ result, errors }) => {
                    if (result) {
                      login({ ...payload, password: newPassword }).then(
                        (success) => {
                          if (success) {
                            // router.push('/presentation');
                            router.push('/positivador');
                          } else {
                            message.error(
                              'Não foi possível efetuar o login. Verifique o cnpj e senha informados'
                            );
                          }
                        }
                      );
                    }

                    if (errors?.length) {
                      message.error(errors.join(', '));
                      return;
                    }
                  });
              }}
            >
              Alterar senha
            </Button>
          </FormWrapper>
          <FooterWrapper onClick={() => setPageState('temp_password')}>
            Voltar
          </FooterWrapper>
        </PageWrapper>
      );
    }
    default:
      return <></>;
  }
};

// Styling Components
const FormTitle = styled.h2`
  color: #ffffff;
  font-size: 32px;
  font-weight: bolder;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  height: 100vh;
  background: no-repeat center url(${smBackgroundImg});
  background-size: cover;

  button {
    display: inline;
    vertical-align: middle !important;
    height: 60px !important;
    line-height: 5px !important;
    padding: 20px 20px !important;
  }
`;

const LogoWrapper = styled.div`
  flex-basis: 33%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const FormWrapper = styled.form`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex-grow: 1;

  * {
    margin-bottom: 30px;
  }

  .ant-input-suffix {
    margin: auto auto -7px auto !important;
    font-size: 15pt;
  }

  .icon-password-custom {
    color: white;
    margin: auto 20px;
  }
`;

const Info = styled.p`
  color: white;
  font-size: 10;
  line-height: 0.1;
`;

const FooterWrapper = styled.div`
  max-height: 60px;
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  a {
    color: white;
  }
`;

export default Login;
